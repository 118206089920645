import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Container, Card, Button, ListGroup, Form } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { AuthContext } from "../context/authContext";

const QuestionSingle = () => {
  const { currentUser } = useContext(AuthContext);
  const { id } = useParams();
  const [question, setQuestion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [answers, setAnswers] = useState({});
  const [newAnswer, setNewAnswer] = useState("");

  useEffect(() => {
    axios.get(`https://kidpedia.net/api/questions/${id}`).then((res) => {
      setQuestion(res.data);
      setLoading(false);
    }).catch((error) => {
      console.error("Error fetching question:", error);
      setLoading(false);
    });
  }, [id]);

  const addAnswer = () => {
    if (!newAnswer.trim()) return;
    axios
      .post(`https://kidpedia.net/api/questions/${id}/answers`, { answer: newAnswer })
      .then(() => {
        setAnswers((prev) => ({
          ...prev,
          [id]: "",
        }));
        setNewAnswer("");
        axios.get(`https://kidpedia.net/api/questions/${id}`).then((res) => {
          setQuestion(res.data);
        });
      })
      .catch((error) => {
        console.error("Cevap eklerken hata oluştu:", error);
      });
  };

  if (loading) {
    return (
      <Container className="mt-5">
        <div className="d-flex align-items-center justify-content-center">
          <FaSpinner className="me-2" style={{ fontSize: "1.5rem" }} spin /> Soru yükleniyor...
        </div>
      </Container>
    );
  }

  if (!question) {
    return (
      <Container className="mt-5">
        <div className="d-flex align-items-center justify-content-center">
          Soru bulunamadı.
        </div>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <Card>
        <Card.Body>
          <Card.Title>
            <strong>{question.category}:</strong> {question.question}
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            Soruyu soran: {question.user} - {new Date(question.created_at).toLocaleDateString()}
          </Card.Subtitle>
          <div className="mt-3">
            <h6>Cevaplar:</h6>
            <ListGroup>
              {question.answers && question.answers.length > 0 ? (
                question.answers.map((answerObj, index) => (
                  <ListGroup.Item key={index}>
                    {answerObj.answer}
                  </ListGroup.Item>
                ))
              ) : (
                <ListGroup.Item>
                  Yöneticilerimiz En Kısa Sürede Cevap Verecektir. Cevap verildiğinde hem buradan hemde mail adresinden cevabınızı görüntüleyebilirsiniz. Lütfen spam (gereksiz) kutusu dahil mail adresinizi kontrol ediniz.
                </ListGroup.Item>
              )}
            </ListGroup>
            {currentUser && currentUser.role === "admin" && ( // Check if user role is admin
              <Form.Group className="mt-2">
                <Form.Control
                  type="text"
                  placeholder="Cevabınızı yazın"
                  value={newAnswer}
                  onChange={(e) => setNewAnswer(e.target.value)}
                />
                <Button className="mt-2" size="sm" onClick={addAnswer}>
                  Cevap Ekle
                </Button>
              </Form.Group>
            )}
          </div>
          <Button as={Link} to="/sorucevap" variant="secondary" className="mt-3">
            Geri Dön
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default QuestionSingle;
