// VideoUpload.js
import React, { useState, useContext } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button, ProgressBar, Modal } from "react-bootstrap";
import { AuthContext } from "../context/authContext";

const VideoUpload = () => {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("");
  const [showModal, setShowModal] = useState(false); // Added state for modal visibility
  const { currentUser, setCurrentUser } = useContext(AuthContext);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
  if (selectedFile && selectedFile.size > 1024 * 1024 * 1024) {
      alert("Dosya boyutu 1 GB'tan büyük olamaz.");
      e.target.value = null;
    } else {
      setFile(selectedFile);
    }
  };

  const allowedExtensions = ["mp4", "webm", "mov", "avi", "ogg"];

  const handleUpload = async (e) => {
    e.preventDefault(); // Prevent default form submission
  
    try {
      if (!file) {
        alert("Lütfen geçerli bir video dosyası seçin.");
        return;
      }
  
      const currentDate = new Date().toISOString().slice(0, 10);
      const fileName = `${currentDate}_${currentUser.firstname}_${currentUser.lastname}_${file.name}`;
      
      // Check if the file extension is in the list of allowed extensions
      const fileExtension = fileName.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        alert("Sadece MP4, WebM, MOV, AVI veya OGG uzantılı dosyaları yükleyebilirsiniz.");
        return;
      }
  
      const formData = new FormData();
      formData.append("file", file, fileName);
  
      const config = {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setUploadProgress(progress);
        },
      };
  
      const res = await axios.post("https://kidpedia.net/api/upload-file-to-google-drive", formData, config); // Updated URL to match your server's endpoint
      if (res.status === 200) {
        setUploadStatus();
        setUploadProgress(0);
        setShowModal(true); // Show modal on success
      } else {
        alert("Video yükleme başarısız oldu.");
      }
    } catch (err) {
      console.log(err);
      setUploadStatus("Yükleme başarısız oldu");
    }
  };
  

  return (
    <>
      <Row>
        <Form.Group controlId="formFile" className="mb-2">
          <Form.Label className="mt-2 text-end fw-bold">
            Video Yükle (Max 1GB, MP4, WebM, MOV, AVI veya OGG)
          </Form.Label>
          <Form.Control
            type="file"
            accept="video/mp4,video/webm,video/ogg,video/mov,video/avi"
            onChange={handleFileChange}
          />
        </Form.Group>
        <Col md={6}>
          <Button variant="success" onClick={handleUpload}>
            Yükle
          </Button>
        </Col>
      </Row>
      {uploadProgress > 0 && (
        <ProgressBar className="mt-3" now={uploadProgress} label={`${uploadProgress}%`} />
      )}
      {uploadStatus && <p>{uploadStatus}</p>}
      
      {/* Added modal component */}
      <Modal show={showModal}>
        <Modal.Header closeButton>
          <Modal.Title>Video Yükleme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Video başarıyla yüklendi.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            Tamam
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VideoUpload;
